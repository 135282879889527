.knotes-policy-modal {
  width: 600px;
  max-height: 800px;
  overflow-y: scroll;

  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }

  & .policy-description {
    width: 100%;
  }

  & .policy-check-container {
    display: flex;
    justify-content: space-between;
  }

  & .detail-button {
    display: flex;
    width: 60px;
    height: 24px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;

    gap: 4px;
    border-radius: 6px;
    background: var(--KD-JET, #333);

    color: var(--KD-SNOW, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 12px */
  }

  & .caution {
    color: var(--KD-LOOK, #F90874);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
  }

  & .confirm-button {
    display: flex;
    height: 48px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 12px;
    background: var(--KD-ID, #502ACC);

    color: var(--KD-SNOW, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.requirement {
  margin-top: 24px;
  height: 305px;
  max-height: 305px;
  overflow-y: scroll;

  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  align-self: stretch;
  border-radius: 12px;
  background: var(--KD-GHOST, #F7F7F7);

  color: var(--KD-GRAY, #999);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}