.knotes-pricing {

  h1 {
    color: var(--KD-ID, #502ACC);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    margin-top: 100px;
    margin-bottom: 20px;
  }

  h2 {
    color: var(--KD-JET, #333);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 200;
    margin-bottom: 30px;
  }

  .knotes-features {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    margin-bottom: 40px;

    & .feature {
      display: flex;
      width: 128px;
      height: 128px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 99px;
      background: var(--KD-ID, #502ACC);
      color: var(--KD-SNOW, #FFF);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
    }
  }

  & .knotes-hook {
    color: var(--KD-JET, #333);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 40px;
  }

  & .pricing-plans {
    display: flex;
    max-width: 1152px;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    margin-bottom: 60px;

    & .plan {
      display: flex;
      padding: 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
      flex: 1 0 0;
      border-radius: 24px;
      background: var(--KD-GHOST, #F7F7F7);

      & .name {
        color: var(--KD-JET, #333);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
      }

      & .pricing {
        color: var(--KD-JET, #333);
        font-size: 32px;
        font-style: normal;
        font-weight: 600;

        & span {
          color: var(--KD-LOOK, #F90874);
        }
      }

      & button {
        display: flex;
        width: 120px;
        height: 48px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: var(--KD-JET, #333);
        color: var(--KD-SNOW, #FFF);
        font-variant-numeric: lining-nums tabular-nums;
        font-feature-settings: 'case' on, 'ss01' on, 'ss02' on, 'ss08' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv09' on;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 16px */

        &.paid {
          border-radius: 12px;
          background: var(--KD-ID, #502ACC);
        }
      }
    }
  }

  & .warning {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    & p {
      color: var(--KD-JET, #333);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    & ul {
      margin-left: 16px;
      color: var(--KD-GRAY, #999);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      li {
        list-style: disc;
      }
    }
  }
}