.toss-payments-modal {
  width: 1200px;
}

.payment-summary {
  margin-left: 30px;
  font-size: 2rem;
}

.payment-button {
  margin-left: 30px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: blue;
  background: aliceblue;
  padding: 10px;
}